import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import FormLabel from '../../atoms/FormLabel';
import FormInput from '../../atoms/FormInput';
import FormStaticField from '../../atoms/FormStaticField';
import TextM from '../../atoms/TextM';
import FormatUtil from '../../utils/FormatUtil';

const FormOrderCosts = ({ quantity, unit, unitPrice, isEditMode, isValidationMode, onChangeQuantity }) => {
    const [quantityDraft, setQuantityDraft] = useState(quantity);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        setQuantityDraft(quantity);
        setTotalPrice(quantity * unitPrice);
    }, [quantity, unitPrice]);

    const handleChangeQuantity = (value) => {
        const _quantity = value ? parseFloat(value) : 0;
        setQuantityDraft(_quantity);
        setTotalPrice(_quantity * unitPrice);
        onChangeQuantity(_quantity);
    };

    return (
        <>
            <Grid spacing="5px" alignItems="center">
                <GridItem cols="3">
                    <FormLabel top bold>
                        Menge {isEditMode ? '*' : ''}
                    </FormLabel>
                    {isEditMode ? (
                        <FormInput
                            ariaLabel="Menge"
                            type="number"
                            value={quantityDraft === null ? '' : quantityDraft}
                            error={isValidationMode && (!quantityDraft || quantityDraft <= 0)}
                            onChange={(e) => handleChangeQuantity(e.target.value)}
                        />
                    ) : (
                        <FormStaticField value={quantity ? FormatUtil.getDecimalFormatted(quantity, 4, 0) : '–'} />
                    )}
                </GridItem>
                <GridItem cols="3">
                    <FormLabel top bold>
                        Einheit
                    </FormLabel>
                    <FormStaticField value={unit || '–'} />
                </GridItem>
                <GridItem cols="3">
                    <FormLabel top bold>
                        Einzelpreis EUR
                    </FormLabel>
                    <FormStaticField value={unitPrice ? FormatUtil.getDecimalFormatted(unitPrice) : '–'} />
                </GridItem>
                <GridItem cols="3">
                    <FormLabel top bold>
                        Gesamtpreis EUR
                    </FormLabel>
                    <FormStaticField value={totalPrice ? FormatUtil.getDecimalFormatted(totalPrice) : '–'} />
                </GridItem>
            </Grid>
            <Block paddingTop="10px" justify="flex-end">
                <TextM>* alle Preise exklusive 5% SRE Aufschlag</TextM>
            </Block>
        </>
    );
};

FormOrderCosts.propTypes = {
    quantity: PropTypes.number,
    unit: PropTypes.string,
    unitPrice: PropTypes.number,
    isEditMode: PropTypes.bool,
    isValidationMode: PropTypes.bool,
    onChangeQuantity: PropTypes.func
};

FormOrderCosts.defaultProps = {
    quantity: null,
    unit: '',
    unitPrice: 0,
    isEditMode: false,
    isValidationMode: false,
    onChangeQuantity: () => {}
};

export default FormOrderCosts;
